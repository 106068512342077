import { TOKEN, API_URLS, STORAGE_KEYS } from "../common.config";
import { downloadData, getData } from "./tc-get-data";
import $ from "./tc-query-selector";

let _DATA_ATTR = "data-new-messages",
  _INDICATOR = $(`[${_DATA_ATTR}]`)[0],
  _NOTIFICATION_HOOK = $("#notifications")[0],
  docTitle = document.title;

let arrivalConfirmationCache = null;

export function checkNotifications() {
  downloadData(API_URLS.helpdesk.count_new_messages)
    .then((response) => {
      return response.result.counter;
    })
    .then((count) => {
      if (count) {
        changeIndicators(count);
      }
    });
}

function changeIndicators(count) {
  document.title = `(${count}) ${docTitle}`;
}

export function getNotifications() {
  return downloadData(API_URLS.notifications.history).then((notifications) => {
    if (notifications === undefined) {
      return;
    }
    let array = [];

    notifications.results.forEach((notification) => {
      if (notification.notification_status === "not_read") {
        array.push(notification);
      }
    });

    if (!array.length) {
      return Promise.reject("There are no new notifications");
    }
    return array;
  });
}

export function getArrivalConfirmation() {
  if (arrivalConfirmationCache) {
    return arrivalConfirmationCache;
  }

  arrivalConfirmationCache = downloadData(API_URLS.notifications.arrival_confirmation)
    .then((confirmation) => {
      if (confirmation === undefined) {
        arrivalConfirmationCache = null;
      }
      return confirmation;
    })
    .catch((err) => {
      arrivalConfirmationCache = null;
      console.warn(err);
      throw err;
    });
  return arrivalConfirmationCache;
}

export function markNotificationAsRead(id) {
  fetch(`${API_URLS.notifications.show}/${id}`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRFToken": TOKEN,
    },
    method: "HEAD",
    credentials: "same-origin",
  })
    .then((response) => {
      if (response.ok) {
        let messageNum = _INDICATOR.getAttribute(_DATA_ATTR);
        if (messageNum > 0) {
          messageNum = messageNum - 1;
          document.title =
            parseInt(messageNum, 10) === 1
              ? document.title.replace(/\([0-9]\)/, "")
              : document.title.replace(/\([0-9]\)/, `${messageNum}`);
        }
      }
    })
    .catch((err) => {
      console.warn(err);
    });
}

export function confirmArrival() {
  fetch(`${API_URLS.notifications.confirm_arrival}`, {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRFToken": TOKEN,
    },
    method: "POST",
    credentials: "same-origin",
    body: "{}",
  }).catch((err) => {
    console.warn(err);
  });
}

export function denyArrival() {
  fetch(`${API_URLS.notifications.deny_arrival}`, {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRFToken": TOKEN,
    },
    method: "POST",
    credentials: "same-origin",
    body: "{}",
  }).catch((err) => {
    console.warn(err);
  });
}
